.filters-groups-container
{
  .loader-container
  {
    position: absolute;
    height: 100%;
  }

  h4:nth-of-type( 2 )
  {
    margin-top: 16px;
  }

  .submit-container
  {
    display: flex;
    align-items: center;
    height: 50px;

    smart-button
    {
      width: fit-content !important;
      height: fit-content !important;
      margin-right: 16px;
      
      button{
        padding: 8px 16px;
      }
    }

    .message-error
    {
      color: var( --color-orange );
    }
  }  
}