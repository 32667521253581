@import './../../../assets/css/colors.scss';

.main-menu
{
  width: 56px;
  height: 100%;
  background-color: var( --color-blue );

  ul
  {
    width: 100%;
    margin: 0;
    padding: 0;

    li
    {
      height: 56px;     

      a{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover{
          background-color: rgba( 255, 255, 255, 0.2 );
          transition: 200ms;
        }

        &.active{
          background-color: var( --color-orange );
          transition: 200ms;
        }
      }
    }
  }
}