.content
{
  display: flex;
  justify-content: center;
  align-items: center;
  
  h1{
    padding: 0;
    margin: 0;
  }
}