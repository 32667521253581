smart-window
{
  --smart-elevation-24: 0;
  --smart-primary: transparent;
  --smart-border: transparent;
  --smart-border-top-left-radius: 0;
  --smart-border-top-right-radius: 0;
  --smart-border-bottom-left-radius: 0;
  --smart-border-bottom-right-radius: 0;
  --smart-background: var( --color-grey3 );

  &.smart-window:not(.smart-container),
  &.smart-window .smart-content-container > .smart-content
  {
    overflow: visible;
    overflow-y: visible;
  }
}