.areyousure-container
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 1.2rem;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  background-color: var( --color-grey3 );
  padding: 16px;
  text-align: center;
  text-wrap: auto;
  z-index: 1;

  .confirm-container
  {
    display: flex;
    flex-flow: row;
    justify-content: space-around;

    smart-button
    {
      width: 100px !important;
      height: 40px !important;
    }
  }
}