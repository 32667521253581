smart-scroll-viewer
{
  --smart-background-color: var( --color-blue );
  --smart-elevation-8: 0;
  --smart-scroll-bar-button-border: 0;
  --smart-border-top-left-radius: 0;
  --smart-border-top-right-radius: 0;
  --smart-border-bottom-left-radius: 0;
  --smart-border-bottom-right-radius: 0;

  .smart-scroll-viewer-content-container ul li.active a
  {
    --smart-ui-state-focus: var( --color-grey1 );
    --smart-ui-state-color-focus: var( --color-blue );
    --smart-item-border-top-left-radius: 0;
    --smart-item-border-top-right-radius: 0;
    --smart-item-border-bottom-left-radius: 0;
    --smart-item-border-bottom-right-radius: 0;
  }
}