smart-grid-cell
{
  .textlink-wrapper
  {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 17px 0 0;

    .textlink-container
    {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      a{
        height: fit-content;
        color: var( --color-blue );
        margin-right: 8px;
    
        &.pic-link{
          display: flex;
          align-items: center;
        }
    
        &:hover{
          color: var( --color-blue );
        }
      }

      .post-text
      {
        height: 100%;
        color: var( --color-grey2 );
        font-size: 0.875rem;   
      }
    }
  }
}