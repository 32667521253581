#keywords-groups-window > .smart-container > .smart-content-container > .smart-content
{
  width: 100%;
  height: 596px;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-around;
  padding: 0;

  .keywords-groups-container
  {
    position: relative;
    width: 450px;
    height: 100%;        
    display: flex;
    flex-flow: column;
    padding: 32px 24px;
    gap: 16px;

    h4:nth-of-type( 2 )
    {
      margin-top: 16px;
    }
    
    .picto-title
    {
      display: flex;
      flex-flow: row;
      margin: 0 0 16px 0;
      font-size: 0.875rem;
      font-weight: 500;
      color: var( --color-grey2 );
      
      svg{
        margin-right: 5px;
      }
    }

    smart-drop-down-list
    {
      --smart-editor-height: 32px;
      --smart-editor-label-padding: 8px;
      --smart-editor-addon-width: 30px;
      --smart-drop-down-list-default-width: 100%;
      --smart-arrow-size: 20px;  

      .smart-drop-down[bottom]
      {
        top: 28px;
      }
    }

    .submit-container
    {
      display: flex;
      align-items: center;
      height: 50px;

      smart-button
      {
        width: fit-content !important;
        height: fit-content !important;
        margin-right: 16px;
        
        button{
          padding: 8px 16px;
        }
      }

      .message-error
      {
        color: var( --color-orange );
      }
    }
  }  
}
