.widget
{
  display: flex;
  flex-flow: row;
  position: relative;
  min-height: 155px;

  .picto-title
  {
    display: flex;
    flex-flow: row;
    margin: 0 0 0 24px;
    font-size: 0.875rem;
    font-weight: 500;
    color: var( --color-grey2 );
    
    svg{
      margin-right: 5px;
    }
  }

  .captions
  {
    margin: 0 0 0 24px;
    padding: 0;
    font-size: 0.88rem;
    color: var( --color-grey2 );

    li{
      display: flex;
      flex-flow: row;
      align-items: center;
      height: 24px;
      line-height: 24px;

      &::before{
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 12px;
      }

      &:nth-child( 1 ){
        &::before{
          background-color: var( --color-yellow );
        }
      }
      &:nth-child( 2 ){
        &::before{
          background-color: var( --color-grey2 );
          opacity: 1;
        }
      }
      &:nth-child( 3 ){
        &::before{
          background-color: var( --color-grey2 );
          opacity: 0.6;
        }
      }
      &:nth-child( 4 ){
        &::before{
          background-color: var( --color-grey2 );
          opacity: 0.3;
        }
      }
    }
  }

  .loader-container{
    position: absolute;
    height: 100%;

    .indicator{
      margin: 0;
    }
  }

  .nodata
  {
    width: 100%;
    height: 100%;
    text-align: center;
    color: var( --color-grey2 );
    font-size: 1rem;
    font-weight: 300;    
  }
}