.chart-displayer-wrapper
{
  padding: 32px 0;
  position: relative;

  .loader-container
  {
    position: absolute;
    height: 100%;
  }

  .drawContainer{
    path[stroke='#e6eaee']
    {
      stroke-dasharray: 4px, 4px;
    }
  }
}