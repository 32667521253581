.reset-password-wrapper
{
  display: flex;
  align-items: center;
  height: 100%;
  color: var( --color-grey2 ) ;
    
  .reset-password{
    smart-button
    {
      font-weight: bold;
      color: var( --color-grey2 ) !important;
      margin-right: 4px;

      button{
        span{
          height: 10px;
        };
      }
    }
  }
}