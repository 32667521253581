.admin-add-users
{
  width: 400px;
  padding: 24px;
  background-color: var( --color-grey3 );

  h4{
    margin: 8px 0;
    color: var( --color-grey2 );
  }

  smart-input.invalid
  {
    --smart-background: var( --color-invalid );
    --smart-background-color: var( --color-blue );
  }

  smart-drop-down-list
  {
    width: 100%;
  }

  .asterisque
  {
    margin-top: 16px;
    font-size: 0.75rem;
    color: var( --color-grey2 );
  }

  .areyousure-container
  {
    top: 0;
    left: 0;
    padding: 50px;
    justify-content: flex-start;

    .confirm-container{
      justify-content: center;
    }
  }

  .confirm-container
  {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    margin-top: 24px;
    gap: 16px;

    smart-button
    {
      justify-content: center;
      font-weight: 400;

      &.empty,
      &.fill
      {
        height: 32px !important;
      }
      &.empty
      {
        --smart-button-flat-color: var( --color-blue ) !important;
        --smart-ui-state-hover: white !important;
        --smart-ui-state-active: white !important;

        width: 117px !important;   
      }
      &.fill
      {
        --smart-button-flat-color: white !important;
        --smart-ui-state-hover: var( --color-grey2 ) !important;
        --smart-ui-state-active: var( --color-grey2 ) !important;
        
        width: 117px !important;
      }
    }
  }
}