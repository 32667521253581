smart-drop-down-button
{
  --smart-font-size: 1.7rem;
  --smart-font-weigt: 200;
  --smart-border: transparent;
  --smart-surface: transparent;
  --smart-surface-color: var( --color-grey2 );

  &:hover,
  &:focus,
  &[opened]
  {    
    --smart-ui-state-hover: var( --color-grey3 );
    --smart-ui-state-active: var( --color-grey3 );
    --smart-ui-state-color-hover: var( --color-grey2 );
    --smart-ui-state-color-active: var( --color-grey2 );
    --smart-border: transparent;
    --smart-outline: transparent;
    --smart-border-top-right-radius: 50%;
    --smart-border-bottom-right-radius: 50%;

    .smart-action-button
    {
      border-color: transparent !important;
      background-color: transparent !important;
    }
  }

  span.smart-drop-down-button
  {
    border-radius: 50%;    
  }

  .smart-drop-down[bottom]
  {
    --smart-background: var( --color-grey3 );
    --smart-elevation-8: none;
    --smart-border-top-right-radius: none;
    --smart-border-top-left-radius: none;
    --smart-border-bottom-right-radius: none;
    --smart-border-bottom-left-radius: none;

    .smart-scroll-viewer-content-container
    {
      width: 100%;
      height: 100%;
    }
  }
}