.login-infos
{
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 16px;
  height: 100%;

  &.authenticated
  {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    height: fit-content;
  }

  .name{
    font-size: 0.94rem;
  }

  .picture
  {
    width: 40px;
    clip-path: circle( 20px at center );
  }

  .paraf
  {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var( --color-grey2 );
    color: white;
    font-size: 0.875rem;
    border-radius: 50%;
    text-transform: uppercase;   
  }

  .loader-container{
    position: absolute;
    height: 100%;

    .indicator{
      margin: 0;
    }
  }

  .message
  {
    position: absolute;
    bottom: 5px;
    width: 100%;
    height: 10px;
    color: var( --color-orange );
    font-size: 0.8rem;

    p{
      margin: 0;
      padding: 0 0 0 2px;
    }
  }

  .smart-form
  {
    height: 43px;

    .smart-input{
      width: 100%;
    }

    .smart-form-group[columns="2"] > .smart-form-group-content
    {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      padding: 0 10px;
    }

    .smart-form-group-buttons,
    .smart-validation-summary,
    .smart-icon-help-circled{
      display: none !important;
    }
  }

  smart-password-input{
    .smart-drop-down-button{
      width: 130px;
    }
  }
}