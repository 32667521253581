/** THEME common **/
smart-grid
{
  --smart-border: var( --color-grey1 );
  --smart-background-color: var( --color-blue );
  
  .smart-container
  {
    .smart-grid-content
    {
      .smart-grid-column-header
      {
        --smart-surface: var( --color-grey1 );    
        --smart-surface-color: var( --color-grey2 );
        --smart-ui-state: var( --color-blue );
        --smart-ui-state-hover: var( --color-grey3 );
        --smart-ui-state-active: var( --color-grey2 );
        --smart-ui-state-color-hover: var( --color-grey2 );
        --smart-ui-state-color-focus: var( --color-blue );
        --smart-ui-state-border-active: var( --color-grey2 );

        div[smart-id='columnContainer'] smart-grid-column
        {
          .smart-grid-icon
          {
            color: var( --color-blue );
            --smart-ui-state-color-hover: var( --color-blue );
            --smart-ui-state-color-focus: var( --color-blue );
          }

          .smart-buttons-group
          {
            .smart-action-button
            {
              --smart-ui-state-border-focus: var( --color-grey3 );
              --smart-ui-state-focus: var( --color-grey3 );
            }
          }
        }

        div[smart-id='columnNearContainer'] smart-grid-column,
        div[smart-id='columnFarContainer'] smart-grid-column
        {
          .smart-input
          {
            --smart-border: var( --color-grey3 );

            background-color: white;

            &::after{
              font-size: 1rem;
            }
          }

          &[selected] .smart-input,
          &[selected="indeterminate"] .smart-input
          {
            background-color: var( --color-grey2 );
          }

          &[selected="indeterminate"] .smart-input
          {
            &:after
            {
              font-size: 0.8rem;
            }
          }
        }

        div[smart-id='columnFarContainer']
        {
          right: 8px;
        }

        .smart-input-overlay
        {
          display: none;
        }
      }

      .smart-grid-scroll-view
      {
        background-color: white;

        .smart-grid-row-container
        {
          smart-grid-row
          {
            --smart-ui-state-hover: white;
            --smart-ui-state-color-hover: var( --color-blue );
  
            smart-grid-cell
            {
              overflow: visible;
              
              .smart-grid-cell-template
              {
                width: 100%;
                height: 100%;
              }
  
              &.keywords
              {
                font-size: 0.9375rem;
              }
            }
  
            .near,
            .far
            {
              smart-grid-cell
              {
                .smart-label
                {
                  border: solid 1px var( --color-grey3 );
                }
  
                &[checkbox]
                {
                  --smart-surface: white;
                }
              }
            }

            .far{
              right: 8px;
            }
  
            &[selected]
            {
              smart-grid-cell
              {
                .smart-label{
                  border-top: none !important;
                }
              }
  
              .near,
              .far
              {
                smart-grid-cell
                {
                  &[checkbox]
                  {
                    --smart-ui-state-active: var( --color-grey2 );              
                    --smart-ui-state-hover: var( --color-grey2 );
                    --smart-ui-state-border-active: var( --color-grey2 );
                    --smart-grid-cell-background-freeze-selected: white;
                    
                    div[data-field='_checkBoxColumn']
                    {
                      &::after{
                        font-size: 1rem;
                        color: white;
                      }
                    }
                  }
                }
              }
  
              .center
              { 
                smart-grid-cell
                {
                  --smart-primary-color: var( --color-blue );
                  --smart-ui-state-selected: white;
                  --smart-ui-state-color-selected: var( --color-blue );
  
                  &::after{
                    --smart-ui-state-color-selected: var( --color-grey3 );
                  }
                }
              }
            }

            &[edit]
            {
              .near,
              .far
              {
                smart-grid-cell
                {
                  &[checkbox]
                  {
                    div[data-field='_checkBoxColumn']
                    {
                      position: relative;
                      width: 14px;
                      height: 14px;

                      &::before{
                        position: relative;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .smart-input-overlay
        {
          display: none;
        }
      }
    }

    .smart-grid-pager
    {
      smart-pager
      {
        --smart-pager-color: var( --color-blue );
        --smart-surface-color: var( --color-grey2 );
        --smart-background: var( --color-blue );
        --smart-ui-state-active: var( --color-grey2 );
        --smart-ui-state-border-active: var( --color-grey2 );

        .smart-container{
          justify-content: center;
        }
      }
    }
  }
}

smart-grid:not([selection_allow-cell-selection])[focus] smart-grid-row[focus] smart-grid-cell:not([editor]):not([header]),
smart-grid:not([selection_allow-cell-selection])[focus] smart-grid-row[focus] smart-grid-cell:not([editor]):not([header]) .smart-label:not([style]),
smart-grid:not([selection_allow-cell-selection])[focus][appearance_show-selection-on-top] smart-grid-row[focus] smart-grid-cell:not([editor]):not([header]),
smart-grid:not([selection_allow-cell-selection])[focus][appearance_show-selection-on-top] smart-grid-row[focus] smart-grid-cell:not([editor]):not([header]) .smart-label
{
  background: var( --color-grey1 ) !important;
  color: var( --color-blue ) !important;
  border-color: var( --color-grey1 ) !important;
}

.smart-grid-column-menu
{
  --smart-elevation-8: none;

  --smart-border-top-left-radius: none;
  --smart-border-top-right-radius: none;
  --smart-border-bottom-left-radius: none;
  --smart-border-bottom-right-radius: none;

  --smart-background: var( --color-grey1 );
  --smart-background-color: var( --color-blue );
  --smart-ui-state-hover: var( --color-grey3 );
  --smart-ui-state-color-hover: var( --color-grey2 );
}

.smart-page-size-selector-drop-down-menu
{
  --smart-input-drop-down-menu-width: 54px !important;

  top: inherit !important;  
  bottom: 36px !important;
}