.filters-removers
{
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 8px;

  .filter
  {
    display: flex;
    flex-flow: row;
    align-items: center;
    width: auto;
    height: 32px;
    border-radius: 16px;
    border: solid 1px var( --color-grey3 );
    box-sizing: border-box;
    padding: 0 16px;
    cursor: pointer;

    .label,
    button
    {
      display: flex;
      flex-flow: row;
      align-items: center;
      height: 16px;
      line-height: 16px;
    }

    button
    {
      background-color: transparent;
      margin-left: 5px;
    }

    &:hover{
      background-color: var( --color-grey3 );

      transition: 200ms;
    }
  }
}