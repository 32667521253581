.comparevalues-wrapper
{
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  height: 100%;

  .insights-compare-trend.grid-core
  {
    .current{
      background-color: white;
      color: var( --color-blue );
    }

    .compare-variation{
      padding: 0 2px;
    }
  }
}

.smart-grid-cell-template[column="volume_value"] .comparevalues-wrapper,
.smart-grid-cell-template[column="impressions_value"] .comparevalues-wrapper,
.smart-grid-cell-template[column="clicks_value"] .comparevalues-wrapper,
.smart-grid-cell-template[column="ctr_value"] .comparevalues-wrapper,
.smart-grid-cell-template[column="position_value"] .comparevalues-wrapper,
.smart-grid-cell-template[column="SEO1PixPos_value"] .comparevalues-wrapper,
.smart-grid-cell-template[column="SEORate_value"] .comparevalues-wrapper,
.smart-grid-cell-template[column="rate_value"] .comparevalues-wrapper
{
  justify-content: flex-end;
}

.smart-grid-cell-template[column="volume_variation"] .comparevalues-wrapper,
.smart-grid-cell-template[column="impressions_variation"] .comparevalues-wrapper,
.smart-grid-cell-template[column="clicks_variation"] .comparevalues-wrapper,
.smart-grid-cell-template[column="ctr_variation"] .comparevalues-wrapper,
.smart-grid-cell-template[column="position_variation"] .comparevalues-wrapper,
.smart-grid-cell-template[column="SEO1PixPos_variation"] .comparevalues-wrapper,
.smart-grid-cell-template[column="SEORate_variation"] .comparevalues-wrapper,
.smart-grid-cell-template[column="rate_variation"] .comparevalues-wrapper
{
  justify-content: flex-start;
}