/** Roboto */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/** PPNeueMontreal **/
@font-face {
  font-family: "PPNeueMontreal";
  src: url( "./../fonts/PPNeueMontreal/PPNeueMontreal-Bold.ttf" ) format( "truetype" );
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url( "./../fonts/PPNeueMontreal/PPNeueMontreal-BoldItalic.ttf" ) format( "truetype" );
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url( "./../fonts/PPNeueMontreal/PPNeueMontreal-Italic.ttf" ) format( "truetype" );
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url( "./../fonts/PPNeueMontreal/PPNeueMontreal-Regular.ttf" ) format( "truetype" );
  font-weight: normal;
  font-style: normal;
}