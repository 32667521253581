smart-button
{
  --smart-button-padding: 0;
  --smart-button-flat-color: var( --color-blue );  
  --smart-ui-state-hover: var( --color-grey3 );
  --smart-ui-state-active: var( --color-grey3 );
  --smart-ui-state-focus: transparent;
  --smart-font-size: 0.875rem;
  --smart-button-text-transform: none;

  width: 40px;
  height: 40px; 

  button{
    display: flex !important;
    align-items: center;
    justify-content: center;    
  }

  &.active
  {
    background-color: var( --color-grey3 );
  }

  &.circle
  {
    --smart-border-top-left-radius: 50%;
    --smart-border-top-right-radius: 50%;
    --smart-border-bottom-left-radius: 50%;
    --smart-border-bottom-right-radius: 50%;
  }

  &.flat
  {
    padding: 0;
    align-items: center;

    &.fill
    {
      --smart-button-flat-color: white;
      --smart-font-weight: 500;

      background-color: var( --color-blue );    
    }

    &.empty{
      border: solid 1px var( --color-blue );
    }
  }

  &[hover],
  &[focus],
  &[active]
  {
    &.flat
    {
      &.fill
      {
        --smart-ui-state-color-hover: white;
        --smart-ui-state-color-active: white;
        --smart-ui-state-color-focus: white;
        --smart-ui-state-hover: var( --color-blue );
        --smart-ui-state-focus: var( --color-blue );
        --smart-ui-state-active: var( --color-blue );
      }

      &.empty
      {
        --smart-ui-state-color-hover: var( --color-blue );
        --smart-ui-state-color-active: var( --color-blue );
        --smart-ui-state-color-focus: var( --color-blue );
      }
    }
  }

  &.down button svg
  {
    transition: all 0.2s ease-in-out;  
  }

  &.up button svg
  {
    transform: rotate( 180deg );
    transition: all 0.2s ease-in-out;  
  }

  &.right button svg
  {
    transform: scaleX( -1 );
    transition: all 0.2s ease-in-out;  
  }

  &.left button svg
  {
    transform: scale( 1 );
    transition: all 0.2s ease-in-out;  
  }
}