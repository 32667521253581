.window-button
{
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;

  .caps-indicator
  {
    position: absolute;
    top: 2px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var( --color-blue );
    font-size: 0.6875rem;
    color: white;
    z-index: 2;
  }
}