.remove-wrapper
{
  height: 100%;

  .window-button
  {
    height: 100%;

    smart-button{
      --smart-button-flat-color: var( --color-grey2 )
    }
  }
}