.burger-menu
{
  position: absolute;
  top: 0;
  left: 360px;
  width: 360px;
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: var( --color-blue );
  z-index: 10;
  
  &.close
  {
    left: -360px;
    opacity: 0;

    transition: 200ms;
  }

  &.open
  {
    left: 0;
    opacity: 1;

    transition: 200ms;
  }

  .burger-menu-nav
  {
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;

    button
    {
      width: 56px;
      height: 56px;
      background-color: var( --color-blue );

      &:hover{
        background-color: rgba( 255, 255, 255, 0.2 );
        transition: 200ms;
      }

      &.selected{
        background-color: var( --color-orange );
        transition: 200ms;
      }
    }
  }

  .burger-menu-container
  {
    height: 100%;
    overflow-y: auto;
    scrollbar-color: rgba( 255, 255, 255, 0.2 ) var( --color-blue );    
  }
}