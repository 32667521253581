smart-slider.smart-slider
{
  --smart-background-color: var( --color-grey2 );
  --smart-font-size: 0.75rem;
  --smart-border: var( --color-grey2 );
  --smart-disabled: var( --color-grey1 );
  --smart-ui-state-active: var( --color-grey2 );

  --smart-primary: var( --color-blue );
  --smart-slider-thumb-width: 16px;
  --smart-slider-thumb-height: 16px;

  .smart-thumb[hover]:before
  {
    transform:scale( 1.5 );
    @keyframes slider-thumb-effect{ 0%, 100% { transform:scale( 1.5 ) } 50%{ transform: scale( 1.25 ) } };
  }

  .smart-tooltip
  {
    --smart-primary: transparent;
    --smart-primary-color: var( --color-grey2 );
    --smart-slider-tooltip-height: 2px;
  }
}