.string-filter
{
  display: flex;
  flex-flow: column;
  gap: 8px;
}

.smart-input-drop-down-menu
{
  position: fixed !important;
}