.admin-mass-actions-keywords
{
  width: 500px;
  padding: 24px;
  background-color: var( --color-grey3 );

  h4{
    color: var( --color-grey2 );
  }

  smart-drop-down-list
  {
    width: 100%;
  }

  .areyousure-container
  {
    top: 0;
    left: 0;
    padding: 50px;
    justify-content: flex-start;

    .confirm-container{
      justify-content: center;
    }
  }

  .confirm-container
  {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    margin-top: 24px;
    gap: 16px;

    smart-button
    {
      justify-content: center;
      font-weight: 400;

      &.empty,
      &.fill
      {
        height: 32px !important;
      }
      &.empty{
        width: 117px !important;          
      }
      &.fill{
        width: 117px !important;
      }
    }
  }
}