smart-grid.smart-grid.grid-keywords
{
  min-height: 1250px;
  
  smart-pager
  {
    .smart-container
    {
      .smart-pager-input-and-label-container{
        display: none;
      }

      justify-content: center !important;
    }
  }
}

.instance-keywords.full-chart
{
  .box4{
    display: none;
  }
}