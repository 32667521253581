.widget
{
  &.compare-values-chart
  {
    display: grid;
    grid-template-rows: 20% 80%;

    &.less-opacity
    {
      .insights-bar-chart{
        opacity: 0.3;
      }
    }
    
    .insights-compare-trend
    {
      margin-left: 24px;
            
      font-size: 0.75rem;
      .current{
        font-size: 0.9375rem;
      }
    }

    &[id='mcp-clicks'],
    &[id='mcp-avg-position'],
    &[id='search-volume']
    {
      .current{
        padding: 8px 21px;
        font-size: 1.5rem;
      }
      .compare{
        padding: 0 10px 0 22px;
        font-size: 0.9375rem;
      }
    }

    .insights-list 
    {
      margin: 0 0 0 24px;

      .insights-compare-trend
      {
        height: 16px;
        line-height: 16px;
        margin-left: 8px;

        .current{
          font-size: 0.6875rem;
          height: 16px;
          line-height: 16px;
        }
      }
    }

    .insights-bar-chart,
    .insights-stacked-column-chart,
    .insights-stacked-area-chart,
    .insights-line-chart
    {
      grid-column-start: 2; 
      grid-row-start: 1;
      grid-row-end: 3;

      z-index: 10;
    }
  }
}