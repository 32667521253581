.dropdownmenu-nested-container
{
  display: flex;
  flex-flow: column;
  gap: 8px;

  smart-list-item
  {
    .smart-content-label
    {
      width: 250px;
    }
  }
  
  smart-drop-down-list
  {
    .smart-content
    {
      .smart-action-button
      {
        .smart-token
        {
          .smart-drop-down-list-selection-label.visible
          {
            display: block;
          }
        }
      }
    }
  }
}