smart-grid.smart-grid.grid-admin-users
{
  min-height: calc( 100vh - 200px ) !important;

  smart-grid-row
  {
    font-size: 0.9375rem;
    
    smart-grid-cell[data-field="picture"]
    {
      padding-left: 24px;

      div[column="picture"]
      {
        display: flex;
        align-items: center;

        img{
          width: 40px;
          clip-path: circle( 20px at center );
        }
  
        .paraf
        {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var( --color-blue );
          color: white;
          font-size: 0.875rem;
          border-radius: 50%;
          text-transform: uppercase;        
        }
      }
    }

    smart-grid-cell[data-field="email"]
    {
      div[data-field="email"]
      {
        &.invalid{
          background-color: var( --color-invalid );
        }
      }
    }
  }

  .remove-element,
  .reset-password
  {
    position: relative;

    smart-window
    {
      position: absolute !important;
      top: 0;
      left: -250px !important;
      width: 150px;
      height: 150px !important;
      
      .smart-content
      {
        padding-top: 0 !important;
      }
    }
  }
}