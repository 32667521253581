.period-calendar
{
  display: flex;
  flex-flow: row wrap;

  .period-infos
  {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 186px;
    margin-right: 10px;

    .current,
    .compare{
      color: var( --color-grey2 );
    }

    .current
    {
      font-size: 0.9rem;
      margin-bottom: 5px;
    }

    .compare
    {
      font-size: 0.7rem;

      span{
        font-weight: 500;
      }
    }    
  }

  smart-window 
  {
    width: 600px !important;
    height: 192px !important;
    left: auto !important;
    right: -24px !important;
    top: 56px !important;
  } 
}