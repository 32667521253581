smart-grid.smart-grid.grid-admin-keywords
{
  min-height: calc( 100vh - 200px ) !important;

  smart-grid-column
  {
    font-size: 0.875rem;
  }

  smart-grid-cell
  {
    font-size: 0.938rem;
  }

  smart-grid-row
  {
    smart-grid-cell:not([selected])
    {
      background-color: white;
    }
  }
  
  smart-pager
  {
    .smart-container
    {
      .smart-pager-input-and-label-container{
        display: none;
      }

      justify-content: center !important;
    }
  }

  smart-grid-cell[data-field='label']
  {
    .textlink-wrapper-text
    {
      cursor: inherit;

      &:hover{
        text-decoration: none;
      }
    }
  }

  smart-grid-cell[data-field='expectedUrl']
  {
    .textlink-wrapper
    {
      .textlink-container
      {
        .textlink-wrapper-text
        {
          width: auto;
          max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }    
  }

  smart-grid-cell[data-field='cat1'],
  smart-grid-cell[data-field='cat2'],
  smart-grid-cell[data-field='cat3']
  {
    .smart-action-button 
    {
      --smart-ui-state-color-active: var( --color-blue );

      .smart-token
      {
        width: 100%;
        height: 100%;
        align-items: center;
      }
    }
  }

  smart-grid-column[data-field="dateAjout"],
  smart-grid-cell[data-field="dateAjout"]
  {
    padding-left: 17px;    
  }

  .remove-element
  {
    position: relative;

    smart-window
    {
      position: absolute !important;
      top: 0;
      left: -250px !important;
      width: 150px;
      height: 150px !important;
      
      .smart-content
      {
        padding-top: 0 !important;
      }
    }
  }
}