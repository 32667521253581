.insights-compare-trend
{
  --height: 24px;
  
  display: flex;
  flex-flow: column;  

  &.flat
  {
    flex-flow: row;
    width: fit-content;
    height: var( --height );
    border-radius: var( --height );
  }

  .current
  { 
    width: fit-content;
    font-size: 0.9375rem;
    border-radius: var( --height );
    background-color: var( --color-blue );
    color: white;

    &.negativ{
      background-color: var( --color-orange );
    }
  }

  .compare-variation
  {
    display: flex;
    flex-flow: row;
    align-items: center;
    width: fit-content;
    height: var( --height );
    font-size: 0.75rem;
    border-radius: var( --height );
    padding: 0 10px 0 0;
  }

  .current,
  .compare{
    height: var( --height );
    line-height: var( --height );    
    padding: 0 10px;
  }

  .variation
  {
    display: flex;
    flex-flow: row;
    align-items: center;

    span{
      margin-left: 2px;

      .decrease{
        color: var( --color-orange );
      }
      
      .constant{
        color: var( --color-yellow );
      }
    }
  }  
}