$color-blue: #0a3856;
$color-orange: #e9540d;
$color-grey: #6b8799; 

:root{
  --translate: 4px;
}

.insights-bar-chart
{
  --smart-chart-default-width: 100%;
  --smart-chart-default-height: 100%;
  
  &.large{
    --translate: 10px;
  }

  .drawContainer
  {
    svg > defs{
      display: none;
    }

    rect[fill="#{$color-grey}"],
    rect[stroke="#{$color-grey}"]
    {
      transform: translate( calc( var( --translate ) * -1 ), 0 );
    }
    
    rect[fill="#{$color-blue}"],
    rect[fill="#{$color-orange}"],
    rect[stroke="#{$color-blue}"],
    rect[stroke="#{$color-orange}"]
    {
      transform: translate( var( --translate ), 0 );
    }
  }
}