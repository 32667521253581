smart-input
{
  --smart-background: white;
  --smart-background-color: var( --color-grey2 );
  --smart-font-size: 0.875rem;
  --smart-text-box-default-width: 100%;
  --smart-editor-label-padding: 8px;
  --smart-border-top-left-radius: 0;
  --smart-border-top-right-radius: 0;
  --smart-border-bottom-left-radius: 0;
  --smart-border-bottom-right-radius: 0;

  border: none;
}