.identity
{
  display: flex;
  align-items: center;

  h2{
    font-size: 1.63rem;
    margin: 0 16px;
    font-weight: bold;
  }

  .picture{
    clip-path: circle( 20px at center );
    width: 40px;
  }
}

.device-location
{
  .button-group-instance
  {
    .smart-button-group:first-child{
      margin-right: 16px;
    }

    .smart-button-group-item[disabled]{
      pointer-events: none;
      opacity: 0.2;
    }
  }
}