smart-switch-button.smart-switch-button
{
  cursor: pointer;

  .smart-container
  {
    &:before
    {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      border: solid 1px;
      border-color: var( --color-grey3 );
      background-color: white;
      box-sizing: border-box;
    }

    .smart-inner-container
    {
      left: calc( -0.8 * (100% - var( --smart-switch-button-thumb-size ) ) );
      
      .smart-thumb
      {
        border: none;
        box-shadow: none;
        background-color: var( --color-grey2 );

        &::before{
          display: none;
        }
      }
    }
  }

  &[checked] .smart-container
  {
    &:before
    {
      border-color: var( --color-grey2 );
      background-color: var( --color-grey2 );
      opacity: 1;
    }

    .smart-inner-container
    {
      left: -3px;
      
      .smart-thumb
      {
        background-color: white;
      }
    }
  }
}