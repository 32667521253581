smart-grid.smart-grid.grid-market
{
  height: calc( 100vh - 144px ) !important;
  min-height: calc( 100vh - 144px ) !important;

  smart-grid-cell[data-field="label"]
  {
    padding: 0 0 0 17px;

    .textlink-wrapper{
      padding: 0;
    }
  }

  smart-pager
  {
    .smart-container
    {
      .smart-pager-input-and-label-container,
      .smart-pager-size-selector-and-label-container{
        display: none;
      }

      justify-content: center !important;
    }
  }
}