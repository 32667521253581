.range-granularity 
{
  display: grid;
  text-align: center;
  height: 80%;

  .item 
  {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    border-left: solid 1px var( --color-grey3 );
    border-right: solid 1px transparent;

    &:last-child {
      border-right: solid 1px var( --color-grey3 );
    }

    span
    {
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      height: 50%; 
      color: var( --color-grey2 );
      text-transform: uppercase;
      font-weight: 500;

      .year{
        font-weight: 400;
      }
    }

    .events-wrapper
    {
      padding-bottom: 5px;

      .events-bubble
      {
        display: flex;
        flex-flow: column;
        justify-content: center;
        width: 17px;
        height: 17px;
        line-height: 17px;
        border-radius: 50%;
        background-color: var( --color-blue );
        color: var( --color-white );
        font-size: 0.65rem;
        cursor: pointer;
      }
    }

    .highlight,
    .highlight-selector
    {
      position: absolute;
      top: -7px;
      left: -1px;
      width: 100%;
      height: 100vh;
      border: solid 1px transparent;
      box-sizing: content-box;
      background-color: transparent;
    }

    .highlight
    {
      display: none;

      &:has( + .highlight-selector:hover )
      {
        display: block;
        border-color: var( --color-grey2 );
      }
    }

    .highlight-selector
    {
      width: 100%;
      height: 72px;
      background-color: transparent;
    }
  }
  
  &.highlight-only
  {
    .item
    {
      border-color: transparent;

      &:hover .highlight
      {
        display: block;
        height: 100%;
        top: -2px;
        border-color: var( --color-grey2 );
      }
    }
  }
}