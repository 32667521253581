.chart-selector
{
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 8px;
  padding-left: 32px;

  .chart-selector-button-container
  {
    display: flex;
    align-items: center;

    smart-switch-button.smart-switch-button
    {
      --smart-switch-button-default-height: 16px;
      --smart-switch-button-default-width: 32px;
      --smart-switch-button-thumb-size: 10px;
    }

    .label
    {
      display: flex;
      align-items: center;
      width: 98px;
      margin-left: 16px;
      cursor: pointer;

      &::before
      {
        content: '';        
        width: 16px;
        height: 16px;
        margin-right: 8px;
        border-radius: 50%;        
      }
    }

    &.impressions .label::before{ background-color: var( --color-blue ); }
    &.clicks .label::before{ background-color: var( --color-orange ); }
    &.ctr .label::before{ background-color: var( --color-yellow ); }
    &.position .label::before{ background-color: var( --color-blue2 ); }

    .value
    {
      height: 16px;
      line-height: 16px;
      margin-left: 32px;
      padding: 0 8px;
      border-radius: 16px;
      font-size: 0.6875rem;
      color: white;
      background-color: var( --color-blue );
    }
  }
}