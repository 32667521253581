smart-scroll-bar[orientation=vertical]
{
  --smart-scroll-button-size: 0px;
  --smart-scroll-bar-track-background: transparent;
  --smart-scroll-bar-thumb-background: var( --color-grey3 );
  --smart-scroll-bar-thumb-background-hover: var( --color-grey3 );
  --smart-scroll-bar-thumb-size: 8px;
  --smart-scroll-bar-thumb-border-top-left-radius: 6px;
  --smart-scroll-bar-thumb-border-top-right-radius: 6px;
  --smart-scroll-bar-thumb-border-bottom-left-radius: 6px;
  --smart-scroll-bar-thumb-border-bottom-right-radius: 6px;

  .smart-scroll-button
  {
    display: none;
  }

  .smart-thumb
  {
    box-sizing: content-box;
    left: 4px;
  }
}