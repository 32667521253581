.admin-content-container
{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 72px auto;
  grid-auto-flow: column;
  gap: 8px;

  h4{
    margin: 0;
    font-family: 'Roboto';
    font-size: 0.875rem;
    color: var( --color-grey2 );
  } 

  .header
  {
    display: grid;
    grid-template-columns: 60% 40%;
    align-items: center;
    padding: 24px;    
  }

  .listbox
  {
    background-color: white;

    .listbox-header
    {
      position: relative;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      padding: 8px;

      .magnifying-glass
      {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      smart-input.category-filter
      {
        --smart-background: var( --color-grey1 );  
        --smart-text-box-default-height: 40px;
        --smart-border-top-right-radius: 0;
        --smart-border-top-left-radius: 0;
        --smart-border-bottom-right-radius: 0;
        --smart-border-bottom-left-radius: 0;

        margin-right: 8px;
      }

      .add-cat-button
      {
        smart-window{
          top: 48px;
          right: 0;
          left: inherit;

          .add-cat-window
          {
            width: 100%;
            padding: 16px;
            display: flex;
            flex-flow: column;
            gap: 16px;
          }

          .confirm-container
          {
            display: flex;
            flex-flow: row;
            justify-content: flex-end;
            width: 100%;
            gap: 16px;

            smart-button
            {
              width: 100px !important;
              height: 40px !important;
            }
          }
        }
      }
    }

    .listbox-content
    {
      padding: 0 8px;
      list-style: none;
      font-size: 0.9375rem;
      color: var( --color-blue );      

      li{
        display: grid;
        grid-template-columns: 60% 40%;
        align-items: center;
        padding-left: 16px;
        height: 40px;

        .col1
        {
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;

          .category-editor
          {
            --smart-background: var( --color-grey3 );

            position: absolute;
            height: 40px;
            border: solid 0.5px var( --color-blue );

            &.invalid
            {
              --smart-background: var( --color-invalid );
            }
          }
        }

        .col2{
          display: grid;
          grid-template-columns: 25% 75%;
          align-items: center;
          justify-items: right;

          .col2_2
          {
            display: flex;
            align-items: center;
            visibility: hidden;

            .remove-cat-button
            {
              smart-window
              {
                top: 0;
                right: 40px;
                left: inherit;
              }
            }

            .caret-right
            {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 40px;
              width: 40px;

              &>svg{
                fill: var( --color-blue );
              }
            }
          }
        }

        &:hover,
        &.selected
        {
          cursor: pointer;

          .col2_2{
            visibility: visible;
          }
        }

        &:hover
        {
          background-color: var( --color-grey1 );

          .col2_2{
            .caret-right{
              &>svg{
                fill: var( --color-grey2 );
              }
            }
          }
        }

        &.selected{
          background-color: var( --color-grey3 );
        }
      }
    }
  }
}