.instances-list
{
  ul{
    margin: 0;
    padding: 0;

    li{
      display: flex;
      flex-flow: row;
      align-items: center;
      height: 48px;      
      line-height: 48px;
      padding: 8px 16px;
      color: white;
      text-decoration: none;
      font-size: 0.938rem;
      cursor: pointer;

      img{
        margin-right: 16px;
        clip-path: circle(24px at center);
      }

      &:hover{
        background-color: rgba( 255, 255, 255, 0.2 );
        transition: 200ms;
      }

      &.selected{
        background-color: var( --color-orange );
        transition: 200ms;
      }
    }
  } 
}