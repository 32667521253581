.admin-menu-container .contextual-options.keywords
{
  .keywords-add-window
  {
    smart-window
    {
      --smart-window-content-padding: 0;
  
      top: 56px !important;
      left: inherit !important;
      right: -88px !important;

      .smart-content
      {
        .loader-container{
          height: 100%;
        }
      }
    }
  }

  .keywords-mass-action
  {
    smart-window
    {
      --smart-window-content-padding: 0;
  
      top: 72px !important;
      left: inherit !important;
      right: 0px !important;
      width: auto;
      height: auto !important;
    }
  }
}