smart-chart
{
  --smart-background: transparent;

  .bar
  {
    display: none;
  }

  .x-axis-labels,
  .values-axis-labels
  {
    font-family: Roboto;
    font-size: 0.75rem;
    fill: var( --color-grey2 );
  }
}