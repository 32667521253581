.tooltip-infos
{
  width: 16px;
  height: 16px;
  margin-left: 8px;
  cursor: pointer;
  color: var( --color-blue );

  &>div
  {
    width: 16px;
    height: 16px;
  }

  .smart-tooltip-content
  {
    width: 200px;
    line-height: 1.1rem;
  }
}