smart-tooltip
{
  
  --smart-background: var( --color-grey1 );
  --smart-background-color: var( --color-grey2 );
  
  --smart-font-weight: normal;
  
  --smart-border-top-left-radius: 0;
  --smart-border-top-right-radius: 0;
  --smart-border-bottom-left-radius: 0;
  --smart-border-bottom-right-radius: 0;

  --smart-tooltip-arrow-color: var( --color-grey2 );

  --smart-elevation-6: none;

  .smart-tooltip-content
  {
    border: solid 1px var( --color-grey3 ) !important;    
  }
}