.insights-line-chart
{
  --smart-chart-default-width: 100%;
  --smart-chart-default-height: 100%;

  height: 100%;

  .drawContainer{
    path[stroke='#D8DEE5']
    {
      stroke-dasharray: 4px, 4px;
    }
  }
}