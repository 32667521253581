:root{
  --translate: 4px;
}

.insights-stacked-column-chart
{
  --smart-chart-default-width: 100%;
  --smart-chart-default-height: 100%;

  display: flex;
  flex-flow: row;

  .custom-xaxis
  {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;    

    li{
      display: flex;
      flex-flow: row;
      align-items: center;
    }
  }
}

#serp-composition-grpsnippets,
#serp-rate-grpsnippets,
#serp-hits-grpsnippets
{
  .insights-stacked-column-chart
  {
    &.large{
      --translate: 10px;
    }

    .drawContainer
    {
      svg > defs{
        display: none;
      }

      rect[fill-opacity="0.3"]
      {
        transform: translate( calc( var( --translate ) * -1 ), 0 );
      }
      
      rect[fill-opacity="1"]
      {
        transform: translate( var( --translate ), 0 );
      }
    }
  }
}