.smart-drop-down-list
{
  --smart-surface: white;
  --smart-surface-color: var( --color-grey2 );
  --smart-ui-state-active: var( --color-grey1 ); 
  --smart-ui-state-hover: var( --color-grey1 );  
  --smart-ui-state-color-hover: var( --color-grey2 );
  --smart-ui-state-border-hover: transparent;
  --smart-ui-state-border-selected: transparent;
  --smart-outline: transparent;
  --smart-border: transparent;
  --smart-border-bottom-left-radius: 0;
  --smart-border-bottom-right-radius: 0;
  --smart-border-top-left-radius: 0;
  --smart-border-top-right-radius: 0;  

  .smart-action-button
  {
    --smart-ui-state-color-active: var( --color-blue );
    
    font-size: 0.8rem;

    .smart-token
    {
      height: 16px;
      line-height: 16px;
      display: flex;
      gap: 2px;

      .smart-drop-down-list-selection-picture{
        display: flex;
        align-items: center;
      }
      .smart-drop-down-list-selection-label{
        display: none;
      }
    }
  }

  .smart-drop-down-button
  {
    --smart-ui-state-color-active: var( --color-grey2 );
  }
  
  .smart-drop-down[top],
  .smart-drop-down[bottom],
  .smart-drop-down[overlay-center]
  {
    --smart-background: white;
    --smart-background-color: var( --color-blue );
    --smart-ui-state-hover: var( --color-grey1 );
    --smart-ui-state-selected: var( --color-grey1 );
    --smart-ui-state-color-hover: var( --color-blue );
    --smart-ui-state-color-selected: var( --color-blue );
    --smart-ui-state-border-hover: transparent;
    --smart-ui-state-border-selected: transparent;
    --smart-item-border-top-left-radius: 0;
    --smart-item-border-top-right-radius: 0;
    --smart-item-border-bottom-left-radius: 0;
    --smart-item-border-bottom-right-radius: 0;
    --smart-font-size: 0.8rem;
    --smart-list-item-padding: 0 5px;
    --smart-list-item-height: 30px;
    --smart-list-item-vertical-offset: 2px;
    
    cursor: pointer;
    box-shadow: 0px 2px 10px rgb(202, 202, 202);

    .smart-list-box
    {
      .smart-list-box-filter-input-container
      {
        margin-top: 0;

        input
        {
          --smart-border-width: 1px;
          --smart-border: var( --color-grey3 );
          border-top: none;
          border-left: none;
          border-right: none;
        }

        &::after
        {
          font-size: 1rem;
          color: var( --color-grey2 );
        }
      }

      .smart-list-item
      {
        --smart-ui-state-focus: transparent;
        --smart-item-border-width: 0;

        .smart-list-item-label-element
        {
          --smart-ui-state-color-focus: var( --color-blue );
        }
      }
    }
  }
}