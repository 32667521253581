.dropdown-wrapper
{
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;

  smart-drop-down-list
  {
    --smart-editor-height: 32px;
    --smart-editor-label-padding: 8px;
    --smart-editor-addon-width: 30px;
    --smart-drop-down-list-default-width: 100%;

    .smart-drop-down
    {
      top: 28px;

      smart-list-item
      { 
        .smart-list-item-label-element
        {
          display: flex;
          flex-flow: row;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }    
  }
}