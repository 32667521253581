.admin-reset-wrapper
{
  width: 100%;
  height: 100%;
  font-size: 1rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 24px;

  h1{
    margin: 20px 0 0;
  }

  a{
    text-decoration: none;
    color: var( --color-grey2 );

    &:hover{
      text-decoration: underline;
    }
  }

  .form-container
  {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 8px;

    input{
      width: 300px;
      height: 32px;
      padding: 8px;
    }
    button{
      width: 48px;
      height: 48px;
      background-color: var( --color-blue );
      color: white;

      &:hover{
        border: solid 1px var( --color-blue );
        background-color: var( --color-grey2 );
      }
    }
  }
}