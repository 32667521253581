.filters-removers
{
  .filter.snippets
  {
    .label{
      margin-right: 5px;
    }

    .pictos{
      display: flex;
      align-items: center;
      margin-right: 8px;
    }

    .presence{
      display: flex;
      flex-flow: row;
      align-items: center;
      font-style: italic;      
    }

    .remove-button
    {
      display: flex;
      align-items: center;
      padding: 0 5px;
      border-right: solid 1px var( --color-grey2 );

      &:last-child{
        border: none;
        padding-right: 0;
      }
    }
  }
}