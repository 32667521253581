.app
{
  width: 100%;
  height: 100%;
  background-color: var( --color-grey1 );

  /** Header */
  header
  {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    background-color: var( --color-grey1 );
    z-index: 1;

    .logo{
      width: 120px;
      margin-left: 32px;
    }

    .login-infos
    {
      margin-right: 40px;
    }
  }

  /** Content */
  .content
  {
    width: 100%;
    height: 100%;
    
    aside
    {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 60;

      nav{
        height: 100%;
      }

      .login-infos{
        position: absolute;
        bottom: 16px;
        left: 0;
      }
    }
    
    .wrapper-pages
    {
      width: 100%;
      height: 100%;
    }
  }

  /** Common classes */
  .hide{
    display: none;
  }

  .bidi-override{
    unicode-bidi: bidi-override;
  }
}