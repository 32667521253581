smart-radio-button.smart-radio-button
{
  --smart-primary: var( --color-grey2 );  
  --smart-primary-color: white;  
  
  --smart-background: white;
  --smart-background-color: var( --color-grey2 );
  
  --smart-ui-state-hover: var( --color-grey3 );
  --smart-ui-state-active: var( --color-grey2 );
  --smart-ui-state-border-hover: white;
  --smart-ui-state-border-active: var( --color-grey2 );
  --smart-ui-state-color-hover: var( --color-grey2 );
  --smart-ui-state-color-active: var( --color-grey2 );

  --smart-border: white;
  --smart-border-width: 1px;  
}