.status-action-wrapper
{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 8px;

  span
  {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
  }
}