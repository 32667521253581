.expected-url-container
{
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 24px;
  padding: 32px 24px;
  min-height: 282px;

  .input-url-container
  {
    display: flex;
    align-items: center;
    gap: 8px;

    button{
      background-color: var( --color-grey3 );
    }

    .smart-input-prefix,
    .smart-input-suffix
    {
      display: none;
    }
  }

  .confirm-container
  {
    padding-right: 32px;
    margin-top: 6px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    gap: 15px;

    smart-button
    {
      justify-content: center;
      font-weight: 400;

      &.empty,
      &.fill
      {
        height: 32px !important;
      }
      &.empty{
        width: 117px !important;          
      }
      &.fill{
        width: 117px !important;
      }
    }
  }
}