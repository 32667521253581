.admin-add-keywords
{
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: var( --color-grey3 );

  table
  {
    width: 100%;

    thead
    {      
      height: 32px;
      vertical-align: top;

      td > div
      {
        display: flex;
        align-items: center;
        color: var( --color-grey2 );

        svg
        {
          margin-right: 8px;
        }
      }
    }

    tbody
    {
      td{
        padding: 0;
        line-height: 0;
      }
    }

    tfoot{
      td{
        font-size: 0.75rem;
        color: var( --color-grey2 );
      }
    }
    
    smart-input,
    smart-drop-down-list
    {
      height: 32px;
      line-height: normal;
      width: 100%;
    }

    smart-input.invalid
    {
      --smart-background: var( --color-invalid );
      --smart-background-color: var( --color-blue );
    }
    smart-drop-down-list.invalid
    {
      --smart-surface: var( --color-invalid );   
      --smart-surface-color: var( --color-blue );
    }
  }

  .confirm-container
  {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    gap: 16px;
    padding: 16px 16px 0 0;

    smart-button
    {
      &.empty{
        --smart-button-flat-color: var( --color-blue ) !important;
        --smart-ui-state-hover: white !important;
        --smart-ui-state-active: white !important;
      }

      &.fill{
        --smart-button-flat-color: white !important;
        --smart-ui-state-hover: var( --color-grey2 ) !important;
        --smart-ui-state-active: var( --color-grey2 ) !important;
      }

      width: 100px !important;
      height: 40px !important;
    }
  }
}