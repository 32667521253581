.status-action-menu-container
{
  position: relative;
  width: 264px;
    
  color: var( --color-grey2 );

  ul, li{
    margin: 0;
    padding: 0;
  }

  li
  {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    list-style: none;
    cursor: pointer;

    &::before
    {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      margin: 0 12px 0 0;
    }
    
    &:hover{
      background-color: var( --color-grey1 );
    }

    &.selected::before
    {
      background-color: var( --color-grey2 ); 
    }
  }

  .expected-url
  {
    position: absolute;
    top: 0;
    right: 264px;
    width: 560px;
    background-color: var( --color-grey3 );
    border-right: solid 1px white;
    z-index: 1;
    display: none;

    &.open{
      display: block;
    }
  }

  .status
  {
    li.selected::before
    {
      width: 12px;
      height: 12px;
      margin: 0 18px 0 6px;
      border-radius: 50%;
    }
  }

  .follow-status
  {
    li{
      height: 0;
      opacity: 0;

      transition: opacity 500ms, height 500ms ease-in-out 300ms;
    }
    
    li::before{
      margin-right: 26px;
    }
    li.selected::before{
      mask: url( './../../../assets/img/pictos/check.svg' ) left 50% / 24px 24px no-repeat;
    }

    &.open
    {
      li{
        height: 40px;
        opacity: 1;

        transition: height 500ms, opacity 500ms ease-in-out 300ms;
      }
      
      border-bottom: solid 1px var( --color-grey1 );
    }
  }

  .action
  {
    .define-expected-url.selected,
    .new-page.selected{
      background-color: var( --color-grey1 );
    }

    .define-expected-url::before,
    .new-page::before{
      background-color: var( --color-grey2 );
    }

    .define-expected-url::before{
      mask: url( './../../../assets/img/pictos/link.svg' ) left 50% / 24px 24px no-repeat;      
    }

    .new-page::before{
      mask: url( './../../../assets/img/pictos/file-plus.svg' ) left 50% / 24px 24px no-repeat;
    }
  }

  .loader-container
  {
    height: 100%;

    .indicator
    {
      margin-top: 75px !important;
    }

    li
    {
      display: block;

      &:before{
        width: 0;
        height: 0;
      }
    }
  }
}