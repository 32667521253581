.calendar-selector
{
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: space-around;
  padding: 0 8px;
  
  .current,
  .compare,
  .confirm
  {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }

  .current,
  .compare
  {
    --smart-editor-height: 32px;
    --smart-editor-label-padding: 15px;
    --smart-editor-addon-width: 30px;
    --smart-drop-down-list-default-width: 264px;
    --smart-date-time-picker-default-width: 136px;
    --smart-arrow-size: 20px;  

    width: 100%;

    .smart-drop-down[bottom]
    {
      top: 32px;
    }
  }

  .confirm
  {
    gap: 15px;

    smart-button
    {
      &.empty,
      &.fill
      {
        height: 32px;
      }
      &.empty{
        width: 92px;          
      }
      &.fill{
        width: 65px;
      }
    }
  }
}