.admin-menu-container
{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  color: white;

  .loader-container
  {
    position: absolute;
    height: 100%;
  }
  
  .menu-container
  {
    display: flex;
    align-items: center;
    height: 100%;
    list-style: none;
    padding: 0 0 0 16px;

    li{
      display: flex;
      align-items: center;
      height: 45px;
      gap: 8px;
      padding: 0 16px;
      cursor: pointer;
      border-bottom: solid 1px transparent;

      .count
      {
        padding: 2px 8px;
        font-size: 0.688rem;
        background-color: var( --color-blue );
        border-radius: 11px;
      }

      &.selected{
        border-bottom-color: white;
      }

      &:hover
      {
        border-bottom-color: rgba( 255, 255, 255, 0.3 );

        transition: border-bottom-color 500ms;
      }
    }
  }

  .contextual-options
  {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 100%;

    smart-button-group
    {
      --smart-border: var( --color-grey3 );
      --smart-border-width: 1px;
      --smart-background: var( --color-grey2 );
      --smart-background-color: var( --color-grey1 );
      --smart-ui-state-selected: var( --color-grey1 );
      --smart-ui-state-hover: var( --color-grey1 );
      --smart-ui-state-active: var( --color-grey1 );
      --smart-ui-state-focus: var( --color-grey1 );
      --smart-ui-state-color-selected: var( --color-grey2 );
      --smart-ui-state-color-hover: var( --color-grey2 );
      --smart-ui-state-color-active: var( --color-grey2 );
      --smart-ui-state-color-focus: var( --color-grey2 );
    }

    .users-add-window,
    .keywords-add-window,
    .keywords-import-window
    {
      smart-button
      {
        --smart-button-flat-color: rgba( 255, 255, 255, 0.2 );  
        --smart-ui-state-hover: rgba( 255, 255, 255, 0.2 );
        --smart-ui-state-active: rgba( 255, 255, 255, 0.2 );

        svg{
          fill: var( --color-grey3 );
        }

        &.active
        {
          border: none;
          background-color: rgba( 255, 255, 255, 0.2 );
        }
      }
    }

    .keywords-mass-action,
    .keywords-mass-action smart-button
    {
      width: 72px;
      height: 100%;
    }
  }
}