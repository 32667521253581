.smart-data-view-header-drop-down.customize-panel
{
  --smart-elevation-8: none;
  --smart-background: var( --color-grey1 );
  --smart-background-color: var( --color-blue );

  position: fixed !important;
  left: auto !important;
  right: 8px !important;

  .smart-column-panel-find-container
  {
    --smart-background: white !important;
    
    border: none;

    input
    {
      --smart-grid-panel-background: white;
      --smart-grid-panel-color: var( --color-grey2 );
      
      padding-left: 8px;
    }    
  }

  .smart-sortable
  {
    .smart-grid-panel-item
    {
      --smart-primary: var( --color-grey2 );

      .toggle-visibility:focus{
        --smart-primary: var( --color-blue );
      }

      &::after,
      &::before{
        display: none;
      }
    }
  }

  .smart-grid-panel-container-footer
  {
    margin-top: 8px;
    justify-content: flex-end;

    .smart-grid-panel-reset-button
    {
      display: none;
    }

    .smart-grid-panel-cancel-button,
    .smart-grid-panel-apply-button 
    {
      text-transform: none;
    }

    .smart-grid-panel-cancel-button
    {
      --smart-border-radius: 0;

      --smart-surface: var( --color-grey3 );
      --smart-surface-color: var( --color-blue );
      --smart-border: var( --color-blue );
      --smart-ui-state-color-selected: var( --color-blue );
      --smart-ui-state-selected: var( --color-grey3 );

      &:hover{
        border-color: var( --color-blue );
      }
    }

    .smart-grid-panel-apply-button 
    {
      --smart-border-radius: 0;

      --smart-primary: var( --color-blue );
      --smart-primary-color: white;
      --smart-ui-state-color-selected: white;
      --smart-ui-state-selected: var( --color-blue );

      &:hover{
        border-color: var( --color-blue );
      }
    }
  }
}