.insights-list
{
  list-style: none;
  padding: 0;
  width: fit-content;
  min-width: 204px;

  li
  {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 16px;
    line-height: 16px;
    padding-left: 24px;
    margin: 8px 0;

    .picto-label
    {
      display: flex;
      flex-flow: row;
      align-items: center;

      span
      {
        display: block;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    svg{
      margin-right: 8px;
    }

    &::before
    {
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      content: '';
    }

    &.blue::before,
    &.orange::before,
    &.yellow::before,
    &.grey::before{
      opacity: 0.8;
    }

    &.blue::before{  background-color: var( --color-blue ) }
    &.orange::before{  background-color: var( --color-orange ) }
    &.yellow::before{  background-color: var( --color-yellow ) }
    &.grey::before{  background-color: var( --color-grey2 ) }

    &.snippet-paa::before{  background-color: var( --snippet-color-paa ); }
    &.snippet-popular-products::before{  background-color: var( --snippet-color-popular-products ); }
    &.snippet-images::before{  background-color: var( --snippet-color-images ); }
    &.snippet-videos::before{ background-color:  var( --snippet-color-videos ); }
    &.snippet-knowledge-panel::before{ background-color:  var( --snippet-color-knowledge-panel ); }
    &.snippet-local-results::before{ background-color:  var( --snippet-color-local-results ); }
    &.snippet-recipes::before{ background-color:  var( --snippet-color-recipes ); }
    &.snippet-map::before{ background-color:  var( --snippet-color-map ); }
    &.snippet-carousel::before{ background-color:  var( --snippet-color-carousel ); }

    &.total{
      .insights-compare-trend{
        .current{
          background-color: white;
          color: var( --color-blue );
          border: solid 1px var( --color-grey2 );
        }
      }
    }
  }  
}