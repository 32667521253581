.admin-menu-container .contextual-options.users
{
  .users-add-window
  {
    margin-right: 16px;

    smart-window
    {
      --smart-window-content-padding: 0;
  
      top: 56px !important;
      left: inherit !important;
      right: -16px !important;

      .smart-content
      {
        .loader-container{
          height: 100%;
        }
      }
    }
  }
}