smart-check-box.smart-check-box
{
  --smart-primary: var( --color-grey3 );
  --smart-ui-state-active: white;
  --smart-background: white;
  --smart-background-color: var( --color-blue );
  
  --smart-border: transparent;
  --smart-ui-state-border-hover: white;
  --smart-ui-state-border-active: white;
  --smart-border-width: 1px;
  
  --smart-font-size: 0.94rem;
  --smart-check-box-default-size: 16px;

  .smart-input,
  .smart-label{
    margin: 0 !important;
  }

  &[checked] .smart-input
  {
    --smart-primary: var( --color-grey2 );
    --smart-ui-state-active: var( --color-grey2 );   
    --smart-ui-state-border-active: var( --color-grey2 );
  }

  &[focus]:not([checked]) .smart-input
  {
    --smart-primary: white;
  }
}